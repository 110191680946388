

import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { DetailedList, Exwarehouse, Material, MaterialType, Warehouse } from '@/types/material'
import { Page } from '@/types/common'
import { ProjectUser } from '@/types/project'
import { mathMul } from '@/utils/math'

@Component({ name: 'ExwarehouseAdd' })
export default class extends Vue {
  @Ref('form') readonly form!: ElForm
  formData: Exwarehouse = {
    projectId: '',
    warehouseId: '',
    checkoutDate: '',
    checkoutUser: '',
    checkoutExplain: '',
    detailedList: []
  }

  loading = false

  props = {
    value: 'materialTypeId',
    label: 'materialTypeName',
    children: 'childNode',
    emitPath: false
  }

  materialTypeData: MaterialType[] = []
  materialData: Material[] = []
  warehouseData: Warehouse[] = []
  projectUserList: ProjectUser[] = []

  rules = {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'blur' }
    ],
    warehouseId: [
      { required: true, message: '请选择出库仓库', trigger: 'blur' }
    ],
    checkoutDate: [
      { required: true, message: '请选择出库日期', trigger: 'blur' }
    ],
    checkoutUser: [
      { required: true, message: '请选择领用人', trigger: 'blur' }
    ],
    detailedList: [
      { required: true, type: 'array', message: '请添加出库物料明细', trigger: 'blur' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadMaterialType()
  }

  changeMaterialType (row: DetailedList) {
    row.materialId = ''
    row.materialName = ''
    row.materialCode = ''
    row.company = ''
    row.specifications = ''
    row.operationStock = ''
    row.frontStock = ''
    row.materialList = []
    row.unitPrice = ''
    row.marketPrice = ''
    row.totalPrice = ''
  }

  loadMaterialData (type: string, row: DetailedList, value: string) {
    if (!value) {
      this.materialData = []
    } else {
      const params = {
        materialTypeId: row.materialTypeId,
        warehouseId: this.formData.warehouseId,
        materialName: '',
        materialCode: ''
      }
      if (type === 'materialName') {
        params.materialName = value
      }
      if (type === 'materialCode') {
        params.materialCode = value
      }
      this.loading = true
      this.$axios.get<Page<Material>>(this.$apis.material.selectStockByList, params).then(res => {
        row.materialList = res.list
      }).finally(() => {
        this.loading = false
      })
    }
  }

  @Watch('formData.projectId')
  loadProjectUserList () {
    this.$axios.get<Page<ProjectUser>>(this.$apis.project.selectProjectUserByList, {
      projectId: this.formData.projectId
    }).then(res => {
      this.projectUserList = res.list || []
    })
  }

  @Watch('formData.projectId')
  loadWarehouseData () {
    this.changeWarehouse()
    this.$axios.get<Page<Warehouse>>(this.$apis.material.selectWarehouseByList, {
      projectId: this.formData.projectId
    }).then(res => {
      this.warehouseData = res.list
    })
  }

  @Watch('formData.warehouseId')
  changeWarehouse () {
    this.formData.detailedList = []
  }

  deleteDetailedList (index: number) {
    this.formData.detailedList.splice(index, 1)
  }

  materialChange (materialId: string, row: DetailedList) {
    console.log('materialId', materialId)
    const item = (row.materialList as Material[]).find(data => data.materialId === materialId) as Material
    row.company = item.company
    row.materialCode = item.materialCode
    row.specifications = item.specifications
    row.materialTypeId = item.materialTypeId
    row.frontStock = item.stock
  }

  loadMaterialType () {
    this.$axios.get<MaterialType[]>(this.$apis.material.selectMaterialTypeByList).then(res => {
      this.materialTypeData = res
    })
  }

  submit () {
    const form = JSON.parse(JSON.stringify(this.formData)) as Exwarehouse
    this.form.validate(valid => {
      if (valid) {
        const setItems = new Set(form.detailedList.map(item => (item.warehouseId as string) + item.materialCode))
        if (setItems.size !== form.detailedList.length) {
          this.$message({ type: 'warning', message: '当前出库物料明细已存在相同记录，不可添加' })
          return
        }
        const items = []
        const regNeg = /^([1-9][0-9]*|[0-9])(\.\d{0,3})?$/
        for (let i = 0; i < form.detailedList.length; i++) {
          const item = form.detailedList[i]
          const frontStock = item.frontStock as string
          if (!/^[1-9][0-9]{0,8}$/.test(item.operationStock)) {
            this.$message({ type: 'warning', message: '出库数量为正整数' })
            return
          } else if (parseInt(item.operationStock) > parseInt(frontStock)) {
            this.$message({ type: 'warning', message: '出库数量不得大于当前库存' })
            return
          } else if (item.marketPrice && !regNeg.test(item.marketPrice)) {
            this.$message({ type: 'warning', message: '出库市场价应为整数或三位以内小数' })
            return
          } else if (item.unitPrice && !regNeg.test(item.unitPrice)) {
            this.$message({ type: 'warning', message: '出库单价应为整数或三位以内小数' })
            return
          } else {
            items.push({
              materialId: item.materialId,
              operationStock: item.operationStock,
              marketPrice: item.marketPrice,
              unitPrice: item.unitPrice,
              totalPrice: item.totalPrice
            })
            // 单价、市场价为空不传字段
            if (item.unitPrice === '') {
              delete items[items.length - 1].unitPrice
            }
            if (item.marketPrice === '') {
              delete items[items.length - 1].marketPrice
              delete items[items.length - 1].totalPrice
            }
          }
        }
        form.detailedList = items
        this.$axios.post(this.$apis.material.insertCheckout, form).then(() => {
          this.$router.back()
        })
      }
    })
  }

  addMaterial () {
    if (!this.formData.warehouseId) {
      this.$message({ type: 'warning', message: '请选择出库仓库' })
    } else {
      this.formData.detailedList.push({
        materialTypeId: '',
        warehouseId: '', // 仓库id
        materialName: '',
        materialCode: '',
        materialId: '', // 物料id
        company: '',
        frontStock: '',
        specifications: '',
        operationStock: '', // 出库数量
        unitPrice: '', // 单价
        marketPrice: '', // 市场价
        totalPrice: '' // 总价
      })
    }
  }

  // 市场价、数量改变
  getPrice (row: DetailedList) {
    const regNeg = /^([1-9][0-9]*|[0-9])(\.\d{0,3})?$/
    row.marketPrice = row.marketPrice ? String(parseFloat(row.marketPrice)) === 'NaN' ? '' : String(parseFloat(row.marketPrice)) : ''
    if (row.marketPrice && row.operationStock && regNeg.test(row.marketPrice)) {
      row.totalPrice = String(mathMul(row.marketPrice, row.operationStock, 3)) === 'NaN' ? '' : String(mathMul(row.marketPrice, row.operationStock, 3))
    } else {
      row.totalPrice = ''
    }
  }

  // 单价改变
  getUnitPrice (row: DetailedList) {
    row.unitPrice = row.unitPrice ? String(parseFloat(row.unitPrice)) === 'NaN' ? '' : String(parseFloat(row.unitPrice)) : ''
  }
}
